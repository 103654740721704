/* eslint-disable jsx-a11y/alt-text */
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  BorderImg,
  MaterialInput,
  MaterialItem,
  MaterialList,
} from "./ColorRugItem.styled";
import { selectColorRug } from "../../../redux/constructor/constructor";

const ColorRugItem = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { typeM, colorRug, colorRugSet } = useSelector(
    (state) => state.constructorRug
  );

  const handleChangeColor = (value) => {
    dispatch(selectColorRug(value));
  };

  return (
    <MaterialList>
      {colorRugSet.map(({ img, value, title }) => {
        const selectedValue = typeM === "romb" ? value[0] : value[1];

        return (
          <MaterialItem key={title}>
            <MaterialInput
              type="radio"
              name="colorRug"
              id={selectedValue}
              checked={colorRug === selectedValue}
              value={selectedValue}
              onChange={(e) => handleChangeColor(e.target.value)}
            />
            <label
              htmlFor={selectedValue}
              title={t(`constructor.colorRugList.${selectedValue}`)}
            >
              <BorderImg>
                <img src={img} />
              </BorderImg>
            </label>
          </MaterialItem>
        );
      })}
    </MaterialList>
  );
});

export default ColorRugItem;
