import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IoCloseSharp } from 'react-icons/io5';
import {
  CloseBtn,
  ProductWrapper,
  StyledBtn,
  StyledModal,
  StyledTextPrice,
  StiledPriceContainer,
  StyledTitle,
} from './ModalBasket.styled';
import ModalBackdrop from '../../../components/common/ModalBackdrop/ModalBackdrop';
import { deleteName } from '../../../redux/basket/basket';
import Table from '../Table/Table';

const ModalBasket = ({ close }) => {
  const dispatch = useDispatch();
  const { orders, totalPrice } = useSelector((state) => state.basket);
  const numberOrders = orders.length;
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(deleteName());
    };
  }, [dispatch]);

  return (
    <ModalBackdrop close={close}>
      <StyledModal
        onClick={(e) => e.stopPropagation()}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <CloseBtn onClick={close}>
          <IoCloseSharp color="black" />
        </CloseBtn>
        <ProductWrapper>
          <StyledTitle margin="0 0 20px 0">{t('basket.table.title')}</StyledTitle>
          <div>
            <p>Кількість товарів</p>
            <p>{numberOrders}</p>
          </div>
          {/* Таблица с заказами */}
          <Table />

          <StiledPriceContainer>
            <StyledTextPrice>{t('modalBasket.price')}</StyledTextPrice>
            <StyledTextPrice>{totalPrice} ₴</StyledTextPrice>
          </StiledPriceContainer>
        </ProductWrapper>
        <StyledBtn to="/basket">{t('modalBasket.btn')}</StyledBtn>
      </StyledModal>
    </ModalBackdrop>
  );
};

export default ModalBasket;
