import { useTranslation } from "react-i18next";
import styled from "styled-components";

const DescrComponent = () => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      <h3>
        {t("discount.title.1")} <span>{t("discount.title.2")}</span>:
      </h3>
      <Box1>
        <h4>{t("discount.classic.title")}</h4>
        <p>
          <span>{t("discount.classic.text1")}</span> 1195 ₴<br />
          <span>{t("discount.classic.text2")}</span> 1895 ₴<br />
          <span>{t("discount.classic.text3")}</span> 2920 ₴<br />
          <span>{t("discount.classic.text4")}</span> 1660 ₴<br />
        </p>
      </Box1>
      <h4>{t("discount.3d.title")}</h4>
      <p>
        <span>{t("discount.3d.text1")}</span> 1365 ₴ <br />
        <span>{t("discount.3d.text4")}</span> 1445 ₴ <br />
        <span>{t("discount.3d.text5")}</span> 1545 ₴
      </p>
      <p>
        <span>{t("discount.3d.text2")}</span> 2135 ₴<br />
        <span>{t("discount.3d.text4")}</span> 2265 ₴ <br />
        <span>{t("discount.3d.text5")}</span> 2535 ₴
      </p>
      <p>
        <span>{t("discount.3d.text3")}</span> 3295 ₴<br />
        <span>{t("discount.3d.text4")}</span> 3405 ₴ <br />
        <span>{t("discount.3d.text5")}</span> 3675 ₴
      </p>
      <p>
        <span>{t("discount.3d.text6")}</span> 1660 ₴
      </p>

      <p>{t("discount.text")}</p>
    </SWrapper>
  );
};

export default DescrComponent;

const SWrapper = styled.div`
  margin-bottom: 24px;

  h3 {
    margin-bottom: 15px;
  }

  h3 > span {
    color: ${(p) => p.theme.colors.accentColor};
  }

  h4 {
    margin-bottom: 8px;
  }

  p > span {
    font-weight: 500;
  }

  p:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Box1 = styled.div`
  margin-bottom: 20px;
`;
