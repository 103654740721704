import styled from "styled-components";

// ТИП МАТЕРИАЛА
export const BorderImg = styled.div`
  border: 2px solid transparent;
  border-radius: 6px;
  overflow: hidden;

  box-shadow: 0px 0px 5px 0px #88a5cc;

  img {
    width: 50px;
    height: 50px;

    /* добавил стили */
    @media screen and (max-width: 568px) {
      width: 25px;
      height: 25px;
    }
  }

  transition: transform 0.3s ease, border 0.3s ease;
`;

export const MaterialList = styled.ul`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  li:not(:last-child) {
    margin-right: 24px;
  }

  /* добавил стили */
  @media screen and (max-width: 568px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    justify-items: center;

    li:not(:last-child) {
      margin: 0;
    }
  }
`;

export const MaterialItem = styled.li`
  width: 50px; /* добавил стили */
  height: 50px; /* добавил стили */

  margin-bottom: 24px;
  label {
    cursor: pointer;
    :focus ${BorderImg}, :hover ${BorderImg} {
      border: 2px solid ${(p) => p.theme.colors.accentColor};
    }
  }

  /* добавил стили */
  @media screen and (max-width: 568px) {
    width: 25px;
    height: 25px;
  }
`;

export const MaterialInput = styled.input`
  -webkit-appearance: none;
  opacity: 0;
  position: absolute;
  top: 0;

  &:checked + label ${BorderImg} {
    border: 2px solid ${(p) => p.theme.colors.accentColor};
  }
`;
