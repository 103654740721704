/* eslint-disable jsx-a11y/alt-text */
import { useSelector } from 'react-redux';
import { Suspense, lazy, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import {
  CloseBtn,
  EdgePosytion,
  HeelPosytion,
  ImgBox,
  LabelPosytion,
  MainWrapper,
  StyledMySwiper,
  StyledSwiper,
  StyledSwiperSlide,
} from './ConstructorImage.styled';
import useToggle from '../../../hooks/useToggle';
import { EXAMPLE_IMG } from './exampleImg';
import Spinner from '../../common/Spinner/Spinner';

const SweeperBtn = lazy(() => import('./SweeperBtn'));
const ModalBackdrop = lazy(() => import('../../common/ModalBackdrop/ModalBackdrop'));

const ConstructorImage = () => {
  const { close, open, isOpen } = useToggle();
  const [initialSlide, setInitialSlide] = useState(0);
  const { currentImg, currentEdge, heel, brandLabel, colorRugSet } = useSelector((state) => state.constructorRug);

  const handleSlideClick = (index) => {
    setInitialSlide(index);
    open();
  };

  if (!colorRugSet) return null;

  return (
    <Suspense fallback={<Spinner />}>
      <StyledSwiper mobile="mobile" spaceBetween={10} slidesPerView={5} freeMode={true}>
        {EXAMPLE_IMG.map((src, index) => (
          <StyledSwiperSlide key={index} onClick={() => handleSlideClick(index)}>
            <img src={src} />
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>

      <MainWrapper>
        <ImgBox>
          {/* коврик */}
          {!currentImg ? <img src="/images/constructor/mainImg/colorsAreMissing.png" /> : <img src={currentImg?.img} />}

          {/* кантик */}
          <EdgePosytion>
            <img src={currentEdge?.img} />
          </EdgePosytion>

          {/* подпятник */}
          {heel && (
            <HeelPosytion>
              <img src="/images/constructor/options/pod-icon.png" />
            </HeelPosytion>
          )}

          {/* шыльдик */}
          {brandLabel === 'item0' ||
            (brandLabel !== '' && (
              <LabelPosytion>
                <img src="/images/constructor/options/brand-icon.png" />
              </LabelPosytion>
            ))}
        </ImgBox>
      </MainWrapper>

      {/* нижний свайпер */}
      <StyledSwiper spaceBetween={10} slidesPerView={5} freeMode={true}>
        {EXAMPLE_IMG.map((src, index) => (
          <StyledSwiperSlide key={index} onClick={() => handleSlideClick(index)}>
            <img src={src} />
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>

      {/* модалка с примерами  */}
      {isOpen && (
        <ModalBackdrop close={close}>
          <CloseBtn src="/images/icons/close.svg" alt="Close" onClick={close} />
          <StyledMySwiper loop={true} initialSlide={initialSlide} spaceBetween={10} watchSlidesProgress={true}>
            {EXAMPLE_IMG.map((src, index) => (
              <StyledSwiperSlide key={index}>
                <img src={src} />
              </StyledSwiperSlide>
            ))}
            <SweeperBtn />
          </StyledMySwiper>
        </ModalBackdrop>
      )}
    </Suspense>
  );
};

export default ConstructorImage;
