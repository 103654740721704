/* eslint-disable jsx-a11y/alt-text */
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectMaterialType } from '../../../redux/constructor/constructor';
import { BorderImg, MaterialInput, MaterialItem, MaterialList } from './TypeMaterialList.styled';

const TypeMaterialList = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { typeM } = useSelector((state) => state.constructorRug);

  const handleChangeMaterial = (value) => {
    dispatch(selectMaterialType(value));
  };

  return (
    <MaterialList>
      {type.map(({ img, value, title }) => {
        return (
          <MaterialItem key={title}>
            <MaterialInput
              type="radio"
              name="material"
              id={value}
              checked={typeM === value}
              value={value}
              onChange={(e) => handleChangeMaterial(e.target.value)}
            />
            <label htmlFor={value} title={t(`constructor.typeMList.${value}`)}>
              <BorderImg>
                <img src={img} />
              </BorderImg>
            </label>
          </MaterialItem>
        );
      })}
    </MaterialList>
  );
};

export default TypeMaterialList;
