import { createSlice } from '@reduxjs/toolkit';
import { CTALOG_PLANNER } from '../../data/dbAccessories';
const initialState = {
  imgAcc: '',
  title: '',
  typeM: '', //тип материала ева\эко
  size: '', //размер аксесуара
  color: '', //ЦВЕТ
  catalog: CTALOG_PLANNER,
  sizeOptions: [],
  colorOptions: [],

  price: 0,
};

const accessoriesSlice = createSlice({
  name: 'accessories',
  initialState,
  reducers: {
    popelateInitialState: (state, { payload }) => {
      const { material, img, title, sizes, colors } = payload;
      state.imgAcc = img;
      state.title = title;
      state.typeM = material;
      state.price = sizes[0].price;
      state.size = sizes[0].value;

      state.sizeOptions = sizes || [];
      state.colorOptions = colors || [];
    },

    selectTypeM: (state, { payload }) => {
      state.typeM = payload;
    },

    selectSize: (state, { payload }) => {
      const selectedSize = state.sizeOptions.find((size) => size.value === payload);
      if (selectedSize) {
        state.size = payload;
        state.price = selectedSize.price;
      }
    },

    selectColor: (state, { payload }) => {
      state.color = payload;
    },

    cleanState: (state) => {
      state.imgAcc = '';
      state.title = '';
      state.typeM = '';
      state.price = 0;
      state.size = '';
      state.color = '';
    },
  },
});

export const { popelateInitialState, selectTypeM, selectSize, selectColor, cleanState } = accessoriesSlice.actions;

export const accessoriesReducer = accessoriesSlice.reducer;
// const originalObject = JSON.parse(
//   JSON.stringify(state.filteredLogoBrand)
// );
// console.log("originalObject", originalObject);
