import React from "react";
import styled from "styled-components";

const Container = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default Container;

const StyledContainer = styled.div`
  position: relative;
  max-width: 100%;
  padding: 0 16px;
  margin: 0 auto;
  /* outline: 1px solid red; */

  @media screen and (min-width: 768px) {
    width: 720px;
  }

  @media screen and (min-width: 992px) {
    width: 960px;
  }

  @media screen and (min-width: 1260px) {
    width: 1292px;
    /* width: 1260px; */
  }
`;
