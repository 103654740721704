import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StyledTable, StyledTableContainer, StyledTbody, StyledTh, StyledThead } from './Table.styled';
import Row from './Row';

const Table = () => {
  const { t } = useTranslation();
  const { orders } = useSelector((state) => state.basket);

  return (
    <StyledTableContainer>
      <StyledTable>
        <StyledThead>
          <tr>
            <StyledTh>{t('basket.table.th1')}</StyledTh>
            <th>{t('basket.table.th2')}</th>
            <th>{t('basket.table.th3')}</th>
            <th></th>
          </tr>
        </StyledThead>
        <StyledTbody>
          {orders.map((order, ind) => {
            return <Row key={ind} order={order} />;
          })}
        </StyledTbody>
      </StyledTable>
    </StyledTableContainer>
  );
};

export default Table;
