import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Section = styled.section`
  padding: 60px 0;

  @media screen and (max-width: 563px) {
    padding: 10px 0;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 600px;

  @media screen and (max-width: 1260px) {
    margin-bottom: 960px;
  }

  @media screen and (max-width: 992px) {
    justify-content: center;
    margin-bottom: 1470px;
  }

  @media screen and (max-width: 563px) {
    margin-bottom: 820px;
  }
`;

export const StyledSwiperWrapper = styled.div`
  width: 515px;
  height: 670px;

  @media screen and (max-width: 992px) {
    margin: 0 auto 130px auto;
    height: 440px;
  }

  @media screen and (max-width: 568px) {
    margin: 0 auto;
    width: 100%;
    height: 420px;
  }
`;

// ==================== добавил стили ===================
export const PositionRugWrapper = styled.div`
  @media screen and (max-width: 568px) {
    position: absolute;
    top: 42%;
    left: 10px;
    width: 90px;
    text-align: center;
  }
`;

export const PositionREdgeWrapper = styled.div`
  @media screen and (max-width: 568px) {
    position: absolute;
    top: 42%;
    right: 10px;
    width: 90px;
    text-align: center;
  }
`;
// =======================================

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 620px;
  height: 670px;

  /* outline: 1px solid red; */
  div > h2 {
    text-align: start;
  }

  @media screen and (max-width: 1260px) and (min-width: 992px) {
    margin-left: 50px;
  }

  @media screen and (max-width: 568px) {
    width: 100%;
    height: 370px;
  }
`;

export const StyledPriceBox = styled.div`
  position: relative;
  padding: 40px 0;
  width: 100%;
  height: auto;
  background: url('/images/elements/bg_sota.png') center/cover no-repeat;
`;

export const FlexPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }) => margin || '0'};
`;

export const StyledLine = styled.div`
  width: 100%;
  height: 2px;
  background: white;
  margin: ${({ margin }) => margin || '12px 0 24px 0'};

  @media screen and (max-width: 768px) {
    margin: ${({ mobile }) => mobile || '12px 0 14px 0'};
  }

  @media screen and (max-width: 568px) {
    display: ${({ mobile }) => (mobile ? 'none' : 'block')};
  }
`;

/* ---------------------------------- ОПЦИИ --------------------------------- */

export const TitleOpyion = styled.p`
  margin-bottom: 14px;

  @media screen and (max-width: 568px) {
    font-size: 12px;
  }

  animation: ${({ pulse }) => (pulse ? 'pulse 2s ease 0s 3 normal' : 'none')};

  @keyframes pulse {
    0% {
      color: ${(p) => p.theme.colors.accentColor};
    }
    50% {
      color: ${(p) => p.theme.colors.white};
    }
    100% {
      color: ${(p) => p.theme.colors.accentColor};
    }
  }
`;

/* ------------------------------- ТИП КОВРИКА ------------------------------ */
export const InputContainer = styled.div`
  position: relative;
  width: 406px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1260px) and (min-width: 992px) {
    flex-direction: column;
    width: 100%;
    label:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledLabel = styled.label`
  width: 192px;
  height: 40px;
  padding: 9px 12px;
  text-align: center;
  border: 2px solid ${(p) => p.theme.colors.white};
  transition: transform 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;

  :focus,
  :hover {
    color: ${(p) => p.theme.colors.accentColor};
    border-color: ${(p) => p.theme.colors.accentColor};
  }

  @media screen and (max-width: 1260px) and (min-width: 992px) {
    width: 100%;
  }

  @media screen and (max-width: 550px) {
    width: 152px;
  }
`;

export const StyledInput = styled.input`
  -webkit-appearance: none;

  &:checked + ${StyledLabel} {
    color: ${(p) => p.theme.colors.accentColor};
    border-color: ${(p) => p.theme.colors.accentColor};
  }
`;

export const InputEva = styled(StyledInput)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const InputEco = styled(StyledInput)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const SelectedList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 1260px) {
    align-items: center;
  }
`;

export const SelectItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 24px;
  width: 100%;

  ::after {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    fill: red;
    background: url('/images/icons/arrow.svg') no-repeat center/contain;
  }

  @media screen and (max-width: 1260px) {
    p {
      font-size: 14px;
      width: 28%;
    }

    ::after {
      top: 40%;
      transform: none;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const SelectInputItem = styled(SelectItem)`
  ::after {
    display: none;
  }
`;

export const StyledSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;

  width: 300px;
  height: 50px;
  padding: 0 12px;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 8px;
  background: ${(p) => p.theme.colors.modalColor};

  cursor: pointer;
  color: rgb(194, 194, 194);
  :focus {
    border-color: ${(p) => p.theme.colors.accentColor};
  }

  @media screen and (max-width: 1260px) {
    width: 48%;
  }

  @media screen and (max-width: 992px) {
    width: 34%;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;

export const StyledPriceText = styled.p`
  font-size: 32px;
  font-weight: 400;
`;

export const StyledBtn = styled.button`
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  font-size: ${(p) => p.theme.fontSizes.fs20};

  background-color: transparent;
  color: ${(p) => p.theme.colors.white};
  border: 2px solid ${(p) => p.theme.colors.white};
  transition: transform 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: ${(p) => p.theme.colors.accentColor};
    border-color: ${(p) => p.theme.colors.accentColor};
  }

  @media screen and (max-width: 1260px) {
    font-size: ${({ price }) => (price ? '20px' : '14px')};
    padding: ${({ mobile }) => (mobile ? '12px 30px' : '15px 47px')};
  }

  @media screen and (max-width: 768px) {
    padding: 15px 0px;
    width: ${({ price }) => (price ? '100%' : '50%')};
    margin: ${({ margin }) => margin || '0'};
  }
`;

// подпятник
export const StyledHeelLabel = styled.label`
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  font-size: ${(p) => p.theme.fontSizes.fs20};
  border: 2px solid ${(p) => p.theme.colors.white};

  text-align: center;
  transition: transform 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;

  @media screen and (min-width: 1260px) {
    &:hover {
      color: ${(p) => p.theme.colors.accentColor};
      border: 2px solid ${(p) => p.theme.colors.accentColor};
    }
  }

  @media screen and (max-width: 1260px) {
    font-size: ${({ price }) => (price ? '20px' : '14px')};
    padding: ${({ mobile }) => (mobile ? '12px 30px' : '15px 47px')};
  }

  @media screen and (max-width: 768px) {
    padding: 15px 0px;
    width: ${({ price }) => (price ? '100%' : '50%')};
    margin: ${({ margin }) => margin || '0'};
  }
`;

export const StyledHeelInput = styled.input`
  -webkit-appearance: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;

  &:checked + ${StyledHeelLabel} {
    color: ${(p) => p.theme.colors.accentColor};
    border: 2px solid ${(p) => p.theme.colors.accentColor};
  }
`;

export const StyledLink = styled(Link)`
  display: block;
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  font-size: ${(p) => p.theme.fontSizes.fs20};
  text-align: center;

  background-color: transparent;
  color: ${(p) => p.theme.colors.white};
  border: 2px solid ${(p) => p.theme.colors.white};
  transition: transform 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: ${(p) => p.theme.colors.accentColor};
    border-color: ${(p) => p.theme.colors.accentColor};
  }

  @media screen and (max-width: 1260px) {
    font-size: ${({ price }) => (price ? '20px' : '14px')};
    padding: ${({ mobile }) => (mobile ? '12px 30px' : '15px 42px')};
  }

  @media screen and (max-width: 768px) {
    padding: 15px 0px;
    width: ${({ price }) => (price ? '100%' : '50%')};
    margin: ${({ margin }) => margin || '0'};
  }
`;

export const BorderImg = styled.div`
  border-radius: 6px;
  overflow: hidden;

  img {
    width: 50px;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    margin: 0 12px;
  }
`;

export const ShadowBox = styled.div`
  position: absolute;
  left: 0;
  top: 0%;
  width: 100%;
  height: 440px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgb(0, 0, 0) 120%);
`;
