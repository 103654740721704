/* eslint-disable jsx-a11y/alt-text */
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translateOrderValues from '../../../utils/useTranslatedOrder';
import {
  CounterArrow,
  FlexBox,
  StyledCounterTd,
  StyledDeleteIcon,
  StyledImgWrapper,
  StyledItem,
  StyledLabel,
  StyledList,
  StyledValue,
  TitleBox,
} from './Table.styled';
import { decrement, deleteOrder, increment } from '../../../redux/basket/basket';

const Row = ({ order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    id,
    imgModel,
    title,
    salonScheme,
    heel,
    price,
    totalPrice,
    count,
    titleKey,

    imgAcc,
    color,
    size,

    imgMat,
    type,
  } = order;

  const {
    translatedValues,
    translTypeM,
    translTypeRug,
    translTypeBoart,
    translBrandLabel,
    translColorRug,
    translСolorEdge,
  } = translateOrderValues(order, t);

  const shameString = translatedValues?.join(', ');

  return (
    <tr>
      <td>
        <FlexBox>
          <StyledImgWrapper>
            {imgModel && <img src={imgModel} alt={title} />}
            {imgAcc && <img src={imgAcc} alt={title} />}
            {imgMat && <img style={{ borderRadius: '12px' }} src={imgMat} alt={title} />}
          </StyledImgWrapper>
          <TitleBox>{titleKey ? <h3>{t(`models.modelsTitles.${titleKey}`)}</h3> : <h3>{title}</h3>}</TitleBox>
        </FlexBox>

        <StyledList>
          {salonScheme && (
            <StyledItem>
              <StyledLabel>{t('basket.tableRow.label1')}</StyledLabel>
              <StyledValue>{shameString.length === 0 ? '-' : shameString}</StyledValue>
            </StyledItem>
          )}

          <StyledItem>
            <StyledLabel>{t('basket.tableRow.label2')}</StyledLabel>
            <StyledValue>{translTypeM}</StyledValue>
          </StyledItem>

          {translTypeRug !== 'constructor.typeRugTitle.undefined' && (
            <StyledItem>
              <StyledLabel>{t('basket.tableRow.label3')}</StyledLabel>
              <StyledValue>{translTypeRug}</StyledValue>
            </StyledItem>
          )}

          {translTypeBoart && (
            <StyledItem>
              <StyledLabel>{t('basket.tableRow.label4')}</StyledLabel>
              <StyledValue>{translTypeBoart}</StyledValue>
            </StyledItem>
          )}

          {translColorRug !== 'constructor.colorRugList.undefined' && (
            <StyledItem>
              <StyledLabel>{t('basket.tableRow.label5')}</StyledLabel>
              <StyledValue>{translColorRug}</StyledValue>
            </StyledItem>
          )}

          {translСolorEdge !== 'constructor.colorEdgeList.undefined' && (
            <StyledItem>
              <StyledLabel>{t('basket.tableRow.label6')}</StyledLabel>
              <StyledValue>{translСolorEdge}</StyledValue>
            </StyledItem>
          )}

          {heel && (
            <StyledItem>
              <StyledLabel>{t('basket.tableRow.label7')}</StyledLabel>
              <StyledValue>{heel}</StyledValue>
            </StyledItem>
          )}

          {translBrandLabel && (
            <StyledItem>
              <StyledLabel>{t('basket.tableRow.label8')}</StyledLabel>
              <StyledValue>{translBrandLabel}</StyledValue>
            </StyledItem>
          )}

          {color && (
            <StyledItem>
              <StyledLabel>{t('basket.tableRow.label9')}</StyledLabel>
              <StyledValue>{color}</StyledValue>
            </StyledItem>
          )}

          {size && (
            <StyledItem>
              <StyledLabel>{t('basket.tableRow.label10')}</StyledLabel>
              <StyledValue>{size}</StyledValue>
            </StyledItem>
          )}
        </StyledList>
      </td>
      <StyledCounterTd>
        {type === 'mat' ? (
          <p>{count}</p>
        ) : (
          <>
            <CounterArrow onClick={() => dispatch(decrement({ id, price }))}>
              <img src="/images/icons/count-arrow-left.svg" />
            </CounterArrow>
            <p>{count}</p>
            <CounterArrow onClick={() => dispatch(increment({ id, price }))}>
              <img src="/images/icons/count-arrow-right.svg" />
            </CounterArrow>
          </>
        )}
      </StyledCounterTd>
      <td>{totalPrice} ₴</td>
      <td>
        <StyledDeleteIcon onClick={() => dispatch(deleteOrder(id))}>
          <use href="/images/icons/sprite.svg#trash_bin"></use>
        </StyledDeleteIcon>
      </td>
    </tr>
  );
};

export default Row;
