import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyleList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 40px;
  justify-items: center;

  @media screen and (max-width: 1260px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 543px) {
    gap: 20px;
  }
`;

export const StyleItem = styled.li`
  width: 290px;

  ${Link} {
    display: flex;
    align-items: center;
    width: 100%;
    height: 241px;
    border-radius: 12px;
    overflow: hidden;
    background: ${(p) => p.theme.colors.white};

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 246px;
  }

  @media screen and (max-width: 543px) {
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    ${Link} {
      height: 180px;
    }
  }

  @media screen and (max-width: 424px) {
    ${Link} {
      height: 160px;
    }
  }
`;
