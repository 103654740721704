import React from 'react';
import styled from 'styled-components';

export const HiddenBox = ({ children }) => {
  return <StyledHiddenBox>{children}</StyledHiddenBox>;
};

export const HiddenBox2 = ({ children }) => {
  return <StyledHiddenBox2>{children}</StyledHiddenBox2>;
};

export const HiddenBox1_768 = ({ children }) => {
  return <StyledHiddenBox3>{children}</StyledHiddenBox3>;
};

export const HiddenBox2_768 = ({ children }) => {
  return <StyledHiddenBox4>{children}</StyledHiddenBox4>;
};

const StyledHiddenBox = styled.div`
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
  }
`;

const StyledHiddenBox2 = styled.div`
  display: block;

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

const StyledHiddenBox3 = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
`;

const StyledHiddenBox4 = styled.div`
  display: block;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
