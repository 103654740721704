/* --------------------------------- ТАБЛИЦА -------------------------------- */

import styled from 'styled-components';

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1260px) {
    flex-direction: column-reverse;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 12px;
    text-align: center;
    vertical-align: top;
  }

  @media (max-width: 562px) {
    th,
    td {
      padding: 5px;
    }

    th,
    td {
      font-size: 14px;
    }
  }
`;

export const StyledThead = styled.thead`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`;

export const StyledTableContainer = styled.div`
  max-height: 550px;
  overflow-y: auto;

  @media (max-width: 992px) {
    padding: 0 13px;
    max-height: 650px;
  }
`;

export const StyledTbody = styled.tbody`
  max-height: 550px;
  overflow: hidden;
  @media (max-width: 992px) {
    max-height: 650px;
  }
`;

export const StyledTh = styled.th`
  width: 320px;
  text-align: start !important;
`;

export const StyledCounterTd = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0 10px;
  }
`;

export const CounterArrow = styled.div`
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const TitleBox = styled.div`
  width: 160px;
  text-align: start;

  h3 {
    font-size: 14px;
    font-weight: 600;
  }

  /* @media (max-width: 1260px) {
    width: 100%;
  } */

  @media (max-width: 562px) {
    margin-bottom: 12px;
  }
`;

export const StyledImgWrapper = styled.div`
  overflow: hidden;
  background: ${(p) => p.theme.colors.white};
  width: 83px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media (max-width: 562px) {
    height: auto;
    margin-bottom: 12px;
  }
`;

export const StyledList = styled.ul`
  li:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const StyledItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1260px) {
    font-size: 14px;
  }
`;

export const StyledLabel = styled.p`
  color: rgb(119, 119, 119);
  text-align: left;

  @media screen and (max-width: 1260px) {
    width: 90px;
  }
`;

export const StyledValue = styled.p`
  text-align: left;
  width: 90px;

  @media screen and (max-width: 1260px) {
    width: 70px;
  }
`;

export const StiledPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
`;

export const StyledTextPrice = styled.p`
  font-size: 32px;
`;

export const StyledDeleteIcon = styled.svg`
  width: 24px;
  height: 24px;

  transition: transform 0.3s ease, fill 0.3s ease;
  cursor: pointer;

  &:hover {
    fill: ${(p) => p.theme.colors.accentColor};
  }
`;
