import styled from "styled-components";

// ТИП МАТЕРИАЛА
export const BorderImg = styled.div`
  border: 2px solid transparent;
  border-radius: 6px;
  overflow: hidden;

  box-shadow: 0px 0px 5px 0px #88a5cc;

  img {
    width: 50px;
    height: 50px;
  }

  transition: transform 0.3s ease, border 0.3s ease;
`;

export const MaterialList = styled.ul`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  li:not(:last-child) {
    margin-right: 24px;
  }
`;

export const MaterialItem = styled.li`
  margin-bottom: 24px;
  label {
    cursor: pointer;
    :focus ${BorderImg}, :hover ${BorderImg} {
      border: 2px solid ${(p) => p.theme.colors.accentColor};
    }
  }
`;

export const MaterialInput = styled.input`
  -webkit-appearance: none;
  opacity: 0;
  position: absolute;
  top: 0;

  &:checked + label ${BorderImg} {
    border: 2px solid ${(p) => p.theme.colors.accentColor};
  }
`;
