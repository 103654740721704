import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectSizeBoart, selecttypeRug } from '../../../redux/constructor/constructor';
import { FlexBox, InputList, StyledInput, StyledLabel, HiddenBox, HiddenBox2 } from './TypeRugList.styled';

const TypeRugList = (type) => {
  const { t } = useTranslation();
  const { RUG, BOARD } = type;
  const dispatch = useDispatch();
  const { typeRug, typeBoart } = useSelector((state) => state.constructorRug);

  const handleBoardChange = (value, checked) => {
    dispatch(selectSizeBoart({ value, checked }));
  };

  return (
    <FlexBox>
      <InputList margin="0 0 24px 0">
        <FlexBox>
          <li>
            {RUG.map(({ value, title }) => {
              return (
                <FlexBox key={title} title={t(`constructor.typeRugTitle.${value}`)}>
                  <StyledInput
                    type="radio"
                    name="rug"
                    id={value}
                    checked={typeRug === value}
                    value={value}
                    onChange={(e) => dispatch(selecttypeRug(e.target.value))}
                  />
                  <StyledLabel htmlFor={value}>{t(`constructor.typeRugTitle.${value}`)}</StyledLabel>
                </FlexBox>
              );
            })}
          </li>

          {/* РАЗМЕР БОРТИКОВ */}
          {typeRug === 'Зd' && (
            <HiddenBox2>
              <li>
                {BOARD.slice(0, 1).map(({ value, title }) => {
                  const isChecked = typeBoart === value;
                  return (
                    <FlexBox key={title} title={t(`constructor.boardTitle.${value}`)}>
                      <StyledInput
                        type="checkbox"
                        name="boart"
                        id={`boart-${value}`}
                        checked={isChecked}
                        onChange={(e) => handleBoardChange(value, e.target.checked)}
                      />
                      <StyledLabel htmlFor={`boart-${value}`}>{t(`constructor.boardTitle.${value}`)}</StyledLabel>
                    </FlexBox>
                  );
                })}
              </li>
            </HiddenBox2>
          )}
        </FlexBox>

        {typeRug === 'Зd' && (
          <div>
            <HiddenBox2>
              <li>
                {BOARD.slice(1, 2).map(({ value, title }) => {
                  const isChecked = typeBoart === value;
                  return (
                    <FlexBox key={title} title={t(`constructor.boardTitle.${value}`)}>
                      <StyledInput
                        type="checkbox"
                        name="boart"
                        id={`boart-${value}`}
                        checked={isChecked}
                        onChange={(e) => handleBoardChange(value, e.target.checked)}
                      />
                      <StyledLabel htmlFor={`boart-${value}`}>{t(`constructor.boardTitle.${value}`)}</StyledLabel>
                    </FlexBox>
                  );
                })}
              </li>
            </HiddenBox2>
            <HiddenBox>
              <li>
                {BOARD.map(({ value, title }) => {
                  const isChecked = typeBoart === value;
                  return (
                    <FlexBox key={title} title={t(`constructor.boardTitle.${value}`)}>
                      <StyledInput
                        type="checkbox"
                        name="boart"
                        id={`boart-${value}`}
                        checked={isChecked}
                        onChange={(e) => handleBoardChange(value, e.target.checked)}
                      />
                      <StyledLabel htmlFor={`boart-${value}`}>{t(`constructor.boardTitle.${value}`)}</StyledLabel>
                    </FlexBox>
                  );
                })}
              </li>
            </HiddenBox>
          </div>
        )}
      </InputList>
    </FlexBox>
  );
};

export default TypeRugList;
