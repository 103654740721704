import { translateValue } from '../pages/Basket/Table/translationKey';

const translateOrderValues = (order, t) => {
  const { salonScheme, typeM, typeRug, typeBoart, colorRug, colorEdge, brandLabel } = order;

  const valueScheme = salonScheme?.map(({ value }) => t(`constructor.shameList.${value}`));
  const translatedValues = valueScheme?.map((value) => value);
  const translTypeM = t(`constructor.typeMList.${typeM}`);
  const translTypeRug = t(`constructor.typeRugTitle.${typeRug}`);
  const translTypeBoart = typeBoart === '-' ? '-' : translateValue(typeBoart);
  const translBrandLabel = translateValue(brandLabel);
  const translColorRug = t(`constructor.colorRugList.${colorRug}`);
  const translСolorEdge = t(`constructor.colorEdgeList.${colorEdge}`);

  return {
    translatedValues,
    translTypeM,
    translTypeRug,
    translTypeBoart,
    translBrandLabel,
    translColorRug,
    translСolorEdge,
  };
};

export default translateOrderValues;
