import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

export const MainWrapper = styled.div`
  @media screen and (max-width: 568px) {
    padding: 0px 50px;
  }
`;

export const ImgBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto; //добавил стили

  img {
    border-radius: 12px;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

// позиціонирование кантика
export const EdgePosytion = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  img {
    border-radius: 12px;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

// позиціонирование кантика
export const HeelPosytion = styled.div`
  position: absolute;
  top: 37%; //добавил стили
  left: 52%; //добавил стили
  /* top: 40%; 
  left: 55%; */

  /* width: 20%; */
  width: 15%; //добавил стили
  height: auto;

  img {
    border-radius: 12px;
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const LabelPosytion = styled.div`
  position: absolute;
  top: 60%;
  left: 22%; //добавил стили
  /* left: 15%; */

  width: 10%;
  height: auto;
  transform: rotate(90deg);

  img {
    border-radius: 12px;
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

// СВАЙПЕР ПОД КАРТИНКОЙ
export const StyledSwiper = styled(Swiper)`
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0 !important;

  .swiper-slide {
    width: 25%;
    height: 100%;
    cursor: pointer;

    img {
      border-radius: 6px;
      width: 100%;
      height: 83%;
      object-fit: cover;
    }
  }

  display: ${({ mobile }) => (!mobile ? 'block' : 'none')}!important;

  @media screen and (max-width: 992px) {
    display: ${({ mobile }) => (mobile ? 'block' : 'none')}!important;
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  img {
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

// СВАЙПКЕР В МОДАЛКЕ
export const StyledMySwiper = styled(Swiper)`
  width: 100%;
  height: 80%;

  .swiper-button-next,
  .swiper-button-prev {
    color: red;
  }
`;

export const CloseBtn = styled.img`
  position: absolute;
  top: 5%;
  right: 10%;
  display: block;
  width: 24px;
  height: 24px;

  cursor: pointer;
`;
