import styled from 'styled-components';

export const EquipmentList = styled.ul`
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: center;

  @media screen and (max-width: 1260px) {
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const BorderImg = styled.div`
  border-radius: 6px;
  border: 2px solid transparent;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  @media screen and (min-width: 1260px) {
    :hover {
      border: 2px solid ${(p) => p.theme.colors.accentColor};
    }
  }

  img {
    width: 85px;
    height: 50px;
  }

  transition: transform 0.3s ease, border 0.3s ease;
`;

export const EquipmentItem = styled.li`
  width: 85px;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease, border 0.3s ease;

  label {
    cursor: pointer;
  }

  input[type='checkbox']:checked + label ${BorderImg} {
    border: 2px solid ${(p) => p.theme.colors.accentColor};
  }
`;

export const CheckboxInput = styled.input`
  -webkit-appearance: none;
  opacity: 0;
  position: absolute;
  top: 23%;
  margin-left: 30px;
`;
