const typeBoartMap = {
  bort5: "5 см",
  bort10: "10 см",
};

const labelBrangMap = {
  item0: "-",
  item1: "1 шт",
  items2: "2 шт",
  items4: "4 шт",
  items5: "5 шт",
};

const combinedTranslationMap = {
  ...typeBoartMap,
  ...labelBrangMap,
};

export const translateValue = (value) => {
  return combinedTranslationMap[value];
};
