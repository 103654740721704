import styled from "styled-components";

export const FlexBox = styled.div`
  display: flex;
`;

export const InputList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin || "0"};

  li {
    display: flex;

    div:not(:last-child) {
      margin-right: 24px;
    }
  }

  li {
    /* padding: 12px 0; */
    cursor: pointer;
  }

  li:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 562px) {
    width: 100%;

    li {
      width: 100%;
      /* flex-direction: column; */

      /* div:not(:last-child) {
        margin-right: 0;
        margin-bottom: 24px;
      } */
    }

    li:not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const StyledLabel = styled.label`
  padding: 9px 12px;
  border: 2px solid ${(p) => p.theme.colors.white};

  text-align: center;
  transition: transform 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;

  @media screen and (min-width: 1260px) {
    &:hover {
      color: ${(p) => p.theme.colors.accentColor};
      border: 2px solid ${(p) => p.theme.colors.accentColor};
    }
  }

  @media screen and (max-width: 1260px) {
    font-size: 13px;
  }

  @media screen and (max-width: 562px) {
    width: 100%;
    font-size: 16px;
  }
`;

export const StyledInput = styled.input`
  -webkit-appearance: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;

  &:checked + ${StyledLabel} {
    color: ${(p) => p.theme.colors.accentColor};
    border: 2px solid ${(p) => p.theme.colors.accentColor};
  }
`;

export const HiddenBox = styled.div`
  display: none;

  @media screen and (max-width: 1260px) {
    display: block;
  }
`;

export const HiddenBox2 = styled.div`
  display: block;

  @media screen and (max-width: 1260px) {
    display: none;
  }
`;
