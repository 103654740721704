import React from 'react';
import styled from 'styled-components';

const TitleH2 = (props) => {
  return <StyledTitle {...props} />;
};

export default TitleH2;

const StyledTitle = styled.h2`
  padding: ${({ padding }) => padding || '0'};
  margin: ${({ margin }) => margin || '0'};
  font-size: ${({ constr, theme }) => (constr ? theme.fontSizes.fsConstrH2 : theme.fontSizes.fsClampH2)};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  text-align: center;
`;
