/* eslint-disable jsx-a11y/alt-text */
import { useDispatch, useSelector } from "react-redux";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  BorderImg,
  MaterialInput,
  MaterialItem,
  MaterialList,
} from "./EdgeColorItem.styled";
import { selectColorEdge } from "../../../redux/constructor/constructor";

const EdgeColorItem = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { colorEdge, colorEdgeSet } = useSelector(
    (state) => state.constructorRug
  );

  const handleChangeColor = (value) => {
    dispatch(selectColorEdge(value));
  };

  return (
    <MaterialList>
      {colorEdgeSet.map(({ img, value, title }) => {
        return (
          <MaterialItem key={title}>
            <MaterialInput
              type="radio"
              name="colorEdge"
              id={value}
              checked={colorEdge === value}
              value={value}
              onChange={(e) => handleChangeColor(e.target.value)}
            />
            <label
              htmlFor={value}
              title={t(`constructor.colorEdgeList.${value}`)}
            >
              <BorderImg>
                <img src={img} />
              </BorderImg>
            </label>
          </MaterialItem>
        );
      })}
    </MaterialList>
  );
});

export default EdgeColorItem;
