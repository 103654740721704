import { useEffect } from 'react';
import { useState } from 'react';
import { bodyOverflow } from '../utils/bodyOveflow';

function useToggle(initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const toggle = () => setIsOpen((prevState) => !prevState);

  useEffect(() => {
    bodyOverflow(isOpen);
    return () => {
      bodyOverflow(false);
    };
  }, [isOpen]);

  return { isOpen, open, close, toggle };
}

export default useToggle;
