import { Link } from 'react-router-dom';
import { StyleList, StyleItem } from './AccessoriesList.styled';
import { All_PRODUCT, CTALOG_PLANNER } from '../../../data/dbAccessories';

const AccessoriesList = () => {
  return (
    <StyleList>
      {All_PRODUCT.map(({ id, img, title, page }) => {
        return (
          <StyleItem key={id} title={title}>
            <Link to={`/accessories/${page}/${id}`}>
              <img src={img} alt={title} />
            </Link>
          </StyleItem>
        );
      })}
    </StyleList>
  );
};

export default AccessoriesList;
