import { createSlice } from '@reduxjs/toolkit';
import { CTALOG_MAT } from '../../data/dbAccessories';
const initialState = {
  imgMat: '',
  title: '',
  typeM: '', //тип материала ромб\сота\капля
  color: '', //ЦВЕТ
  catalog: CTALOG_MAT,
  colorOptions: [],
  count: 1,
  basePrice: 1800, // Базовая цена за единицу товара
  price: 1800, // Начальная цена
  priceList: [
    { price: 1300, minCount: 300 },
    { price: 1400, minCount: 50 },
    { price: 1500, minCount: 20 },
    { price: 1600, minCount: 10 },
    { price: 1800, minCount: 1 },
  ],
};

// Функция для расчета базовой цены
const calculateBasePrice = (count, priceList) => {
  for (let i = 0; i < priceList.length; i++) {
    if (count >= priceList[i].minCount) {
      return priceList[i].price;
    }
  }
  return priceList[priceList.length - 1].price;
};

const matSlice = createSlice({
  name: 'mat',
  initialState,
  reducers: {
    popelateMatInitialState: (state, { payload }) => {
      const { type, title, colors } = payload;
      state.imgMat = type[0].img;
      state.title = title;
      state.typeM = type[0].value;

      state.colorOptions = colors || [];
    },

    selectTypeM: (state, { payload }) => {
      const { value, img } = payload;

      state.typeM = value;
      state.imgMat = img;
    },

    selectColor: (state, { payload }) => {
      state.color = payload;
    },

    decrement: (state) => {
      state.count = state.count > 1 ? state.count - 1 : 1;
      state.basePrice = calculateBasePrice(state.count, state.priceList); // Обновляем базовую цену
      state.price = state.basePrice * state.count; // Пересчитываем общую цену
    },

    increment: (state) => {
      state.count = state.count + 1;
      state.basePrice = calculateBasePrice(state.count, state.priceList); // Обновляем базовую цену
      state.price = state.basePrice * state.count; // Пересчитываем общую цену
    },

    setCount: (state, { payload }) => {
      state.count = payload.value > 0 ? payload.value : 1; // Обновление значения
      state.basePrice = calculateBasePrice(state.count, state.priceList); // Обновляем базовую цену
      state.price = state.basePrice * state.count; // Пересчитываем общую цену
    },

    cleanState: (state) => {
      state.imgMat = '';
      state.title = '';
      state.typeM = '';
      state.color = '';
      state.count = 1;
      state.price = 1800;
    },
  },
});

export const { popelateMatInitialState, selectTypeM, decrement, increment, setCount, selectColor, cleanState } =
  matSlice.actions;

export const matReducer = matSlice.reducer;
// const originalObject = JSON.parse(
//   JSON.stringify(state.filteredLogoBrand)
// );
// console.log("originalObject", originalObject);
